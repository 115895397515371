.empresas {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: auto;
    grid-gap: 1rem;

    .card {
        box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        cursor: pointer;
        img {
            width: 100%;
            height: auto;
            transform: scale(0.8);
        }
    }
    .card:hover, .card:active {
        background-color: $gray;
        img{
            transform: scale(1);
        }
    }
}

@media only screen and (min-width: $device-sm) {
    .empresas {
        grid-template-columns: repeat(5, 1fr);
        .card {
            img {
                width: 90%;
                height: auto;
            }
        }
    }
}

@media only screen and (min-width: $device-md) {
    .empresas {
        grid-template-columns: repeat(5, 1fr);
        .card {
            img {
                width: 80%;
                height: auto;
            }
        }
    }
}

@media only screen and (min-width: $device-lg) {
    .empresas {
        grid-template-columns: repeat(5, 1fr);
        .card {
            img {
                width: 70%;
                height: auto;
            }
        }
    }
}

/*@media only screen and (min-width: $device-xl) {

}*/