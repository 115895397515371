.padding-banner{
    padding: 0px 10px !important;
}
.banner-ramo{
    padding-top: 120px !important;
}

.banner-imagenes{

    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }
    
    img{
        width: 100%;
        height: 100%;
    }
    
    .contenedor{
        width: 100%;
        position: relative;
        height: 600px;
    }
    
    .botones{
        font-size: 30px;
        width: 70px;
        height: 70px;
        line-height: 70px;
        border-radius: 50%;
        background-color: rgba(0,0,0,.7);
        text-align: center;
        font-weight: bold;
        position: absolute;
        color: #ffffff;
        box-shadow: 0 0 10px rgba(255, 255, 255, .6);
        -webkit-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
    }
    
    .botones:hover {
        cursor: pointer;
        transform: scale(1.2, 1.2);
    }
    
    .atras{
        left: 2%;
        top: calc(50% - 150px);
    }
    
    .adelante{
        right: 2%;
        top: calc(50% - 150px);
    }
}

.categoria {
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    width: 100%;
    box-shadow: 0 6px 8px 0 rgba(0,0,0,.15);
    display: flex;
    align-items: center;
    .arrow {
        width: 5%;
        background-color: white;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ul {
        display: flex;
        max-height: 80px;
        padding: 12px 10px;
        margin: 0 auto;
        white-space: nowrap;
        width: 90%;
        overflow-x: scroll;
        -ms-overflow-style: none; 
        scrollbar-width: none;
        li {
            text-align: center;
            flex-grow: 1;
            a{
                padding: 10px 12px;
                font-size: 0.8rem;
                text-transform: uppercase;
                text-align: center;
                cursor: pointer;
            } 
            .active {
                border-bottom: 2px solid $primary;
                color: $primary;
                font-weight: bold;
            }
        }
    }  
    ul::-webkit-scrollbar {
        display: none;
        overflow: -moz-scrollbars-none;
    }
}

@media only screen and (min-width: $device-sm) {
    .banner-ramo {
        padding-top: 65px !important;
    }
}