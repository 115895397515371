.modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 2;

    input{
        border: 1px solid $primary;
    }
}

.modal-con {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
    z-index: 2;

    input{
        border: 1px solid $primary;
    }
}


.modal-content {
    width: 100%;
    height: 100%;
    background-color: white;

    .recuperar-clave{
        cursor: pointer;
        color: $dark;
        width: 100%;
        text-align: center;
        margin-bottom: 5px;
    }

    // .content::-webkit-scrollbar {
        // display: none;
    // }

    .borderline{
        border: 1px solid $primary;
        border-radius: 2%;
    }

    .sm{
        height: calc(100% - 350px) !important;
    }

    .content {
        padding-top: 10px;
        overflow-y: scroll;
        width: 80% !important;
        height: calc(100% - 60px);
        padding: 0px 30px;

        .error {
            color: $primary;
            font-size: 0.9rem;
            margin-top: 10px;
            text-align: center;
        }
        
        .modal-logo {
            width: auto;
            height: 45px;
            display: flex;
            margin: auto;
        }
        .centerAlign{
            text-align: center;
        }
    }

    .full-content{
        width: 100% !important;
    }

    .footer {
        width: 80%;
        margin: auto;
        .btn-large{
            height: 30px;
        }
        button {
            cursor: pointer;
            margin: 10px 0px;
        }

        button:disabled{
            cursor: default;
            background-color: gray;
        }
    }

    .no-padding{
        padding: 10px 0px;
        height: 145px !important;
        hr {
            border-style: inset;
            border-width: 1px;
            margin: 13px 0px;
        }
        input {
            margin: 10px 0px;
            width: 100%;
            border: 1px solid $primary;
            height: 30px;
            background-color: white;
            font-size: 0.9rem;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 5px;
        }
    }

    .default{
        padding: 10px 0px;
        height: auto !important;
        hr {
            border-style: inset;
            border-width: 1px;
            margin: 13px 0px;
        }
        input {
            margin: 10px 0px;
            width: 100%;
            border: 1px solid $primary;
            height: 30px;
            background-color: white;
            font-size: 0.9rem;
            padding-left: 10px;
            padding-right: 10px;
            border-radius: 5px;
        }
    }

    .encuesta{
        padding: 10px 5px;
        height: auto !important;
        margin: 10px auto;
        border: 1px solid rgba(128, 128, 128, 0.6);
        border-radius: 5px;
        

        hr {
            border-style: inset;
            border-width: 1px;
            margin: 13px 0px;
        }
        input {
            // margin: 10px 0px;
            border: none;
            height: 50px;
            text-align: initial;
            width: 100%;
        }
    }

    .agendamiento{
        padding: 10px 5px;
        height: auto !important;
        margin: 10px auto;
        border: 1px solid rgba(128, 128, 128, 0.6);
        border-radius: 5px;

        table > tbody > tr > td {
            padding: 5px !important;
        }

        div{
            display: flex;
            margin: 10px auto;
        }

        hr {
            border-style: inset;
            border-width: 1px;
            margin: 13px 0px;
        }
        input {
            margin: 0px auto;
            display: block;
            width: 150px;
        }
    }

    /*------scroll bar---------------------*/
    // ::-webkit-scrollbar {
    //     width: 5px;
    //     height: 7px;
    // }
    // ::-webkit-scrollbar-button {
    //     width: 0px;
    //     height: 0px;
    // }
    // ::-webkit-scrollbar-thumb {
    //     background: $gray;
    //     border: 0px none #ffffff;
    //     border-radius: 0px;
    // }
    // ::-webkit-scrollbar-thumb:hover {
    //     background: $gray;
    // }
    // ::-webkit-scrollbar-thumb:active {
    //     background: $gray;
    // }
    // ::-webkit-scrollbar-track {
    //     background: transparent;
    //     border: 0px none #ffffff;
    //     border-radius: 50px;
    // }
    // ::-webkit-scrollbar-track:hover {
    //     background: transparent;
    // }
    // ::-webkit-scrollbar-track:active {
    //     background: transparent;
    // }
    // ::-webkit-scrollbar-corner {
    //     background: transparent;
    // }

    .title{
        // height: 50px;
        // display: flex;
        justify-content: center;
        align-items: center;
        p{
            font-size: 1rem;
            text-align: center;
            color: $primary;
            font-weight: bold;
            width: 80%;
            margin: 10px auto 0px auto;
        }
        .close-button {
            position: absolute;
            right: 15px;
            text-align: center;
            cursor: pointer;
            border-radius: 0.25rem;
        }
        .close-button:hover {
            opacity: 0.6;
        }
   
    }
}

.show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.list-items {
    width: 100%;
    .item {
        margin: 10px 0px;
        padding: 5px 0px;
        cursor: pointer;
        border-bottom: 1px solid $primary;
        p {
            font-size: 0.8rem;
        }
        .img-product {
            width: 100%;
        }
    }
    .detalle-combo {
        position: relative;
        display: inline-block;
        width: 46%;
        margin: 0 2%;

        .cantidad-detalle-combo{
            font-style: italic;
            color: white;
            position: absolute;
            top: 20px;
            right: 0px;
            background: $secundary;
            padding: 5px 10px;
            border-radius: 10px;
            border: solid black 1px;
        }

        p {
            text-align: center;
        }
    }
    .item:hover {
        background-color: $gray;
    }
}

@media only screen and (min-width: $device-md) {
    .modal-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 400px;
        border-radius: 10px;
        min-height: 500px;
        max-height: 500px;
    }
}

