.navbar-inicial{
    width: 50% !important;
}

.filtro {

    // position: fixed;
    margin-left: auto;
    margin-right: auto;
    // background-color: white;
    width: 100%;
    // box-shadow: 0 6px 8px 0 rgba(0,0,0,.15);
    display: flex;
    align-items: center;

    input[type=date] {
        // display: grid;
        // grid-auto-flow: column;
        width: -webkit-fill-available;
        max-width: 150px;
        border: 1px solid #0085B0;
        border-radius: 5px;
        height: 25px;
        margin: auto;
    }
    .arrow {
        width: 5%;
        background-color: white;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ul {
        display: flex;
        // grid-auto-flow: column;
        width: auto;
        max-height: 80px;
        padding: 12px 10px;
        margin: 0 auto;
        white-space: nowrap;
        width: 90%;
        overflow-x: scroll;
        -ms-overflow-style: none; 
        scrollbar-width: none;
        li {
            text-align: center;
            flex-grow: 1;
            a{
                padding: 10px 12px;
                font-size: 0.8rem;
                text-transform: uppercase;
                text-align: center;
                cursor: pointer;
            } 
            .active {
                border-bottom: 2px solid $primary;
                color: $primary;
                font-weight: bold;
            }
        }
    }  
    ul::-webkit-scrollbar {
        display: none;
        overflow: -moz-scrollbars-none;
    }
}

.select-css {

    margin: 0px 3px;
    // display: grid;
    width: inherit;

    font-size: 12px;
    font-family: 'Arial', sans-serif;
    font-weight: 400;
    color: #444;
    line-height: 1.3;
    padding: .4em .5em .4em .6em;
    max-width: 100%; 
    box-sizing: border-box;
    border: 1px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
    border-radius: .3em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    // background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    //     linear-gradient(to bottom, #ffffff 0%,#f7f7f7 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
    display: none;
}
.select-css:hover {
    border-color: #888;
}
.select-css:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222; 
    outline: none;
}
.select-css option {
    font-weight:normal;
}

@media only screen and (min-width: $device-xl) {
    .navbar-inicial   {
        width: 70% !important;
    }
}

@media only screen and (min-width: $device-lg) {
    .navbar-inicial   {
        width: 70% !important;
    }
}

@media only screen and (min-width: $device-md) {
    .navbar-inicial   {
        width: 70% !important;
    }
}

@media only screen and (min-width: $device-sm) {
    .filtro {
        width: 70% !important;
    }
}