@import url(https://fonts.googleapis.com/css?family=Nunito:300,300i,400,400i,600,600i,700,700i,800,800i,900,900i&display=swap);

$font-type: 'Nunito', serif;  

$primary: #EA0B2A;
$secundary: #009fe3;
$dark: #c7001c;
$ligth: #757575;
$gray: #fafafa;

$nav-height: 60px;
$device-sm: 500px;

$device-sm: 540px;
$device-md: 790px;
$device-lg: 960px;
$device-xl: 1500px;