.main{
    width: 100%;
    background-color: #ffffff;
}

.header-list{
    height: auto;
    background-color: rgba($color: #949494, $alpha: 0.2);
    padding-top: 10px;
    p{
        width: 100%;
        display: block;
    }
}

.ayuda-list{
    display: inline-block;
    padding: 180px 10px;
    width: 100%;

    .seccion-ayuda{
        position: relative;
        width: 100%;        
        margin: 0 0;

        img{
            width: 100%;
        }

        .font-ayuda{
            font-size: 10px;
            font-weight: bold;
            color: white;
        }

        .nombre-vendedor{
            position: absolute;
            top: 32%;
            text-align: center;
            width: 100%;
            left: 20%;
        }

        .telefono-vendedor{
            position: absolute;
            top: 48%;
            left: 60%;
            max-width: 35%;
        }

        .titulo-servicio-cliente {
            position: absolute;
            top: 12%;
            left:48%;
            max-width: 35%;
        }

        .telefono-servicio-cliente {
            position: absolute;
            top: 35%;
            left: 55%;
            max-width: 35%;
        }

        .label-servicio-cliente{
            position: absolute;
            top: 43%;
            left: 55%;
            max-width: 35%;
        }

        .tel-nal-servicio-cliente {
            position: absolute;
            top: 51%;
            left: 55%;
            max-width: 35%;
        }

        .email-nal-servicio-cliente{
            position: absolute;
            top: 69%;
            left: 55%;
            max-width: 35%;
        }
    }
}

.descuentos{
    padding: 80px 10px !important;
    .start{
        display: flex;
        .nombre{
            font-size: 1rem !important;
        }
        .img-descuento{
            width: 25px;
            margin: 0 10px;
        }
    }
}

.pedido-list {
    // margin-left: auto;
    // margin-right: auto;
    // max-width: 1200px;
    padding: 10px 10px;
    width: 100%;

    .seccion-ayuda{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        margin: 10px 0%;
        text-align: left;
        padding: 15px;
        border: 1px dotted black;
        border-radius: 5px;
        background-color: rgba(189, 189, 189,0.2);

        .start{
            width: 80%;
            display: inline-block;
        }

        a {
            color: -webkit-link;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .table-productos{
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
        font-size: 14px;

        th, td{
            text-align: center;
            padding: 8px;
        }

        td{

            position: relative;
            .img-descuento{
                top: 5px;
                right: 15px;
                width: 40px;
                position: absolute; 
            }
            border: 1px solid #ddd;
        }

        thead{
            tr:first-child{

                th{
                    z-index: 1;
                    position: sticky;
                    top: 66px;
                }

                th:first-child{
                    border-top-left-radius: 5px;
                    
                }
                th:last-child{
                    border-top-right-radius: 5px;
                }
            }

            tr:last-child{

                th{
                    z-index: 1;
                    position: sticky;
                    top: 103px;
                }

                th:last-child{
                    border-top-right-radius: 5px;
                }
            }

            th{
                
                background-color: #e7e7e7;
                color: black;
                border: 1px solid #c3c3c3;
            }

            th:empty {
                visibility:hidden;
            }
            th:blank {
                visibility:hidden;
            }
        }

        .titulos{
            padding: 15px;
        }

        tfoot {
            td {
                background-color: #203763;
            }
        }

        tbody{
            tr:nth-child(even) {
                background-color: #f2f2f2;
            }

            tr:last-child{
                td:first-child{
                    border-bottom-left-radius: 5px;
                }
                td:last-child{
                    border-bottom-right-radius: 5px;
                }
            }
        }
        
        .action {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            .distribuir {
                background-color: $dark;
                padding: 12px 33px;
                color: white;
                border-radius: 25px;
                font-size: 0.8rem;
                font-weight: bold;
                margin: 0px 5px;
            }

            .agregar {
                background-color: $dark;
                padding: 12px 33px;
                color: white;
                border-radius: 25px;
                font-size: 0.8rem;
                font-weight: bold;
                margin: 0px 5px;
            }
            .controls {
                border-radius: 25px;
                border: 1px solid $primary;
                padding: 10px 10px;
                display: flex;
                input {
                    width: 60px;
                    padding: 0px 5px;
                    text-align: center;
                    font-size: 1rem;
                }
                button {
                    background-color: transparent;
                    display: flex;
                    opacity: 1;
                }
            }
        }
    }

    .product-list {
        
        .item {
            position: relative;
            display: flex;
            align-items: center;
            border-bottom: 0.5px solid $ligth;
            padding: 10px 0px;
            .start {
                width: 80%;
            }
            .end {
                width: 35%;
            }
            img{
                margin: 0px 1%;
            }
            .nombre {
                color: black;
                font-size: 0.9rem;
            }
            .codigo {
                font-size: 0.8rem;
            }
            .precio {
                color: rgb(94, 87, 87);
                font-size: 1rem;
                font-weight: bold;
            }
            .regular {
                font-size: 0.9rem;
                color: rgb(94, 87, 87);
            }
            .action {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                right: 15px;

                .distribuir {
                    background-color: $dark;
                    padding: 12px 33px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                    margin: 0px 5px;
                }

                .agregar {
                    background-color: $dark;
                    padding: 12px 33px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .controls {
                    border-radius: 25px;
                    border: 1px solid $primary;
                    padding: 10px 10px;
                    display: flex;
                    align-items: center;
                    input {
                        width: 60px;
                        padding: 0px 5px;
                        text-align: center;
                        font-size: 1rem;
                        background-color: #f6f6f6;
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                    button {
                        background-color: transparent;
                        display: flex;
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }

        .item-carrito {
            
            display: flex;
            align-items: center;

            a{
                color: -webkit-link;
                cursor: pointer;
                text-decoration: underline;
            }
            .start {
                width: 80%;
            }
            .end {
                width: 35%;
            }
        }
    }

    .product-card{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        .card {
            text-align: center;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
            padding: 10px;
            display: grid;

            .txt-principal {
                font-size: 1rem;
            }
            .txt-secundario {
                font-family: "Nunito", serif;
                font-size: 0.9rem;
            }
            .precio {
                color: rgb(94, 87, 87);
                font-size: 1rem;
                margin-top: 10px;
                font-weight: bold;
            }

            .card-content{
                display: grid;
            }

            .img-product {
                margin: auto;
            }

            .action {
                display: grid;
                align-items: center;
                justify-content: center;
                margin-top: 10px;

                .distribuir {
                    background-color: $dark;
                    padding: 12px 33px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                    margin: 0px 5px;
                }

                .agregar {
                    display: block !important;
                    margin: 0px auto 5px auto;
                    background-color: $dark;
                    padding: 12px 0px;
                    width: 140px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .btn-eliminar {
                    display: block !important;
                    margin: 0px auto 5px auto;
                    background-color: $secundary;
                    padding: 12px 0px;
                    width: 140px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .controls {
                    border-radius: 25px;
                    border: 1px solid $primary;
                    padding: 10px 10px;
                    display: flex;
                    input {
                        width: 60px;
                        padding: 0px 5px;
                        text-align: center;
                        font-size: 1rem;
                    }
                    button {
                        background-color: transparent;
                        display: flex;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.lista-catalogo{
    margin-bottom: 60px;
}

.lista-agenda{
    padding-top: 60px;
    margin-bottom: 60px;
}

.encabezado-list {
    // margin-left: auto;
    // margin-right: auto;
    // max-width: 1200px;
    padding: 130px 10px 70px 10px !important;
    width: 100%;

    .seccion-ayuda{
        display: inline-block;
        width: 100%;
        margin: 10px 0%;
        text-align: left;
        padding: 15px;
        border: 1px dotted black;
        border-radius: 5px;
        background-color: rgba(189, 189, 189,0.2);

        .start{
            width: 80%;
            display: inline-block;
        }

        a {
            color: -webkit-link;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    button{
        margin: auto 0px;
    }

    .product-list {
        
        .item {
            position: relative;
            display: block;
            width: 98%;
            margin: 10px 1%;
            align-items: center;
            // border-bottom: 0.5px solid $ligth;
            padding: 10px 10px;
            border-radius: 15px;
            background-color: white;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);

            .radio-group{
                label{
                    margin: 15px 10px;
                    display: block;
                }
                input[type=radio] {
                    margin: 0px 5px;
                }
            }
            

            .img-cliente{
                margin: auto 0px;
                img{
                    width: 80px;
                    margin: 0px 10px;
                }
            }

            .start {
                width: 100%;
                display: flex;
            }
            .end {
                width: 100%;
            }
            img{
                margin: 0px 1%;
            }
            .precio {
                color: rgb(94, 87, 87);
                font-size: 1rem;
                font-weight: bold;
            }
            .regular {
                font-size: 0.9rem;
                color: rgb(94, 87, 87);
            }
            .action {
                right: 15px;
                display: flex;
                flex-direction: column;
                margin: 10px 5px;
                // align-items: center;
                justify-content: flex-end;

                .agregar {
                    display: block !important;
                    margin: 0px auto 5px auto;
                    background-color: $dark;
                    padding: 12px 0px;
                    width: 140px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .btn-eliminar {
                    display: block !important;
                    margin: 0px auto 5px auto;
                    background-color: $secundary;
                    padding: 12px 0px;
                    width: 140px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }

                .btn-agendar {
                    background-color: $dark;
                    padding: 12px 20px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }

                .tomar-pedido {
                    background-color: $dark;
                    padding: 12px 20px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .controls {
                    border-radius: 25px;
                    border: 1px solid $primary;
                    padding: 10px 10px;
                    display: flex;
                    align-items: center;
                    input {
                        width: 60px;
                        padding: 0px 5px;
                        text-align: center;
                        font-size: 1rem;
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                    button {
                        background-color: transparent;
                        display: flex;
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }

        .item-carrito {
            
            display: flex;
            align-items: center;

            a{
                color: -webkit-link;
                cursor: pointer;
                text-decoration: underline;
            }
            .start {
                width: 80%;
            }
            .end {
                width: 35%;
            }
        }
    }

    .product-card{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        .card {
            box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
            padding: 10px;
            .img-product {
                width: 100%;
                // height: 100px;
            }
            .nombre {
                font-family: "Nunito", serif;
                font-size: 0.7rem;
                min-height: 40px;
            }
            .codigo {
                font-size: 0.8rem;
            }
            .precio {
                color: rgb(94, 87, 87);
                font-size: 1rem;
                margin-top: 10px;
                font-weight: bold;
            }
            .action {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;

                .distribuir {
                    background-color: $dark;
                    padding: 12px 33px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                    margin: 0px 5px;
                }

                .agregar {
                    background-color: $dark;
                    padding: 12px 33px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .controls {
                    border-radius: 25px;
                    border: 1px solid $primary;
                    padding: 10px 10px;
                    display: flex;
                    input {
                        width: 60px;
                        padding: 0px 5px;
                        text-align: center;
                        font-size: 1rem;
                    }
                    button {
                        background-color: transparent;
                        display: flex;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.padding-cliente-list{
    padding: 175px 10px 15px 10px !important;
}

.cliente-list {
    // margin-left: auto;
    // margin-right: auto;
    // max-width: 1200px;
    padding-bottom: 70px !important;
    width: 100%;

    .table-estadisticas-agendamiento{
        margin: auto;
        width: 70%;
        border-collapse: separate;
        border-spacing: 0;
        font-size: 12px;

        th, td{
            text-align: center;
            padding: 8px;
        }

        td{
            border: 1px solid #ddd;
        }

        thead{

            th:first-child{
                border-top-left-radius: 5px;
            }
            th:last-child{
                border-top-right-radius: 5px;
            }

            th{
                background-color: #e7e7e7;
                color: black;
                border: 1px solid #c3c3c3;
            }

            th:empty {
                visibility:hidden;
            }
            th:blank {
                visibility:hidden;
            }
        }

        tbody {

            tr:last-child{
                td:first-child{
                    border-bottom-left-radius: 5px;
                }
                td:last-child{
                    border-bottom-right-radius: 5px;
                }
            }

        }

        .titulos{
            padding: 15px;
        }

        .color-naranja{
            background-color: #816001
        }

        .color-verde{
            background-color: #385624
        }

        tfoot {
            td {
                background-color: #203763;
            }
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        .action {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            .distribuir {
                background-color: $dark;
                padding: 12px 33px;
                color: white;
                border-radius: 25px;
                font-size: 0.8rem;
                font-weight: bold;
                margin: 0px 5px;
            }

            .agregar {
                background-color: $dark;
                padding: 12px 33px;
                color: white;
                border-radius: 25px;
                font-size: 0.8rem;
                font-weight: bold;
                margin: 0px 5px;
            }
            .controls {
                border-radius: 25px;
                border: 1px solid $primary;
                padding: 10px 10px;
                display: flex;
                input {
                    width: 60px;
                    padding: 0px 5px;
                    text-align: center;
                    font-size: 1rem;
                }
                button {
                    background-color: transparent;
                    display: flex;
                    opacity: 1;
                }
            }
        }
    }

    .seccion-ayuda{
        display: inline-block;
        width: 100%;
        margin: 10px 0%;
        text-align: left;
        padding: 15px;
        border: 1px dotted black;
        border-radius: 5px;
        background-color: rgba(189, 189, 189,0.2);

        .start{
            width: 80%;
            display: inline-block;
        }

        a {
            color: -webkit-link;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    .product-list {
        
        .item {
            position: relative;
            display: flex;
            width: 98%;
            margin: 10px 1%;
            align-items: center;
            // border-bottom: 0.5px solid $ligth;
            padding: 10px 10px;
            border-radius: 15px;
            background-color: white;
            box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);

            .radio-group{
                label{
                    margin: 15px 10px;
                    display: block;
                }
                input[type=radio] {
                    margin: 0px 5px;
                }
            }
            

            .img-cliente{
                img{
                    width: 80px;
                    margin: 0px 30px;
                }
            }

            .start {
                width: 80%;
            }
            .end {
                width: 35%;
            }
            img{
                margin: 0px 1%;
            }
            .precio {
                color: rgb(94, 87, 87);
                font-size: 1rem;
                font-weight: bold;
            }
            .regular {
                font-size: 0.9rem;
                color: rgb(94, 87, 87);
            }

            .exportar{
                width: 100%;
                text-align: center;
                
                .btn-exportar{
                    margin: auto;
                    background-color: $dark;
                    padding: 12px 20px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
            }

            

            .action {
                position: absolute;
                right: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;

                .agregar {
                    display: block !important;
                    margin: 0px auto 5px auto;
                    background-color: $dark;
                    padding: 12px 0px;
                    width: 140px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .btn-eliminar {
                    display: block !important;
                    margin: 0px auto 5px auto;
                    background-color: $secundary;
                    padding: 12px 0px;
                    width: 140px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }

                .btn-agendar {
                    background-color: $dark;
                    padding: 12px 20px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }

                .tomar-pedido {
                    background-color: $dark;
                    padding: 12px 20px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .controls {
                    border-radius: 25px;
                    border: 1px solid $primary;
                    padding: 10px 10px;
                    display: flex;
                    align-items: center;
                    input {
                        width: 60px;
                        padding: 0px 5px;
                        text-align: center;
                        font-size: 1rem;
                    }
                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                    button {
                        background-color: transparent;
                        display: flex;
                        opacity: 1;
                        cursor: pointer;
                    }
                }
            }
        }

        .item-carrito {
            
            display: flex;
            align-items: center;

            a{
                color: -webkit-link;
                cursor: pointer;
                text-decoration: underline;
            }
            .start {
                width: 80%;
            }
            .end {
                width: 35%;
            }
        }
    }

    .product-card{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: auto;
        grid-gap: 1rem;
        .card {
            box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
            padding: 10px;
            .img-product {
                width: 100%;
                // height: 100px;
            }
            .nombre {
                font-family: "Nunito", serif;
                font-size: 0.7rem;
                min-height: 40px;
            }
            .codigo {
                font-size: 0.8rem;
            }
            .precio {
                color: rgb(94, 87, 87);
                font-size: 1rem;
                margin-top: 10px;
                font-weight: bold;
            }
            .action {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;

                .distribuir {
                    background-color: $dark;
                    padding: 12px 33px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                    margin: 0px 5px;
                }

                .agregar {
                    background-color: $dark;
                    padding: 12px 33px;
                    color: white;
                    border-radius: 25px;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .controls {
                    border-radius: 25px;
                    border: 1px solid $primary;
                    padding: 10px 10px;
                    display: flex;
                    input {
                        width: 60px;
                        padding: 0px 5px;
                        text-align: center;
                        font-size: 1rem;
                    }
                    button {
                        background-color: transparent;
                        display: flex;
                        opacity: 1;
                    }
                }
            }
        }
    }
}

.sugerido {
    padding: 80px 10px !important;
    .checkbox {
        margin-bottom: 20px;
    }
}

.contactenos {
    padding: 80px 10px !important;
    display: block;
}

.carrito {
    padding: 80px 10px !important;
    // margin-bottom: 200px;   
}

.carrito-validacion {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid red;
    background-color: rgb(250, 179, 174);
    display: flex;
    border-radius: 5px;
    .contenido{
        margin-left: 10px;
    }
}

.carrito-informacion {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid orange;
    background-color: #ffd7aa;
    display: flex;
    border-radius: 5px;
    .contenido{
        margin-left: 10px;
    }
}

.total {

    position: inherit;
	bottom: 60px;
	padding: 10px 10px;
    width: 100%;
    margin-bottom: 70px;

    .table-productos{
        border-collapse: separate;
        border-spacing: 0;
        width: 100%;
        font-size: 12px;

        th, td{
            text-align: center;
            padding: 8px;
        }

        td{
            border: 1px solid #ddd;
        }

        thead{

            th:first-child{
                border-top-left-radius: 5px;
            }
            th:last-child{
                border-top-right-radius: 5px;
            }

            th{
                background-color: #e7e7e7;
                color: black;
                border: 1px solid #c3c3c3;
            }

            th:empty {
                visibility:hidden;
            }
            th:blank {
                visibility:hidden;
            }
        }

        tbody {

            tr:last-child{
                td:first-child{
                    border-bottom-left-radius: 5px;
                }
                td:last-child{
                    border-bottom-right-radius: 5px;
                }
            }

        }

        .titulos{
            padding: 15px;
        }

        .color-naranja{
            background-color: #816001
        }

        .color-verde{
            background-color: #385624
        }

        tfoot {
            td {
                background-color: #203763;
            }
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        .action {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 10px;

            .distribuir {
                background-color: $dark;
                padding: 12px 33px;
                color: white;
                border-radius: 25px;
                font-size: 0.8rem;
                font-weight: bold;
                margin: 0px 5px;
            }

            .agregar {
                background-color: $dark;
                padding: 12px 33px;
                color: white;
                border-radius: 25px;
                font-size: 0.8rem;
                font-weight: bold;
                margin: 0px 5px;
            }
            .controls {
                border-radius: 25px;
                border: 1px solid $primary;
                padding: 10px 10px;
                display: flex;
                input {
                    width: 60px;
                    padding: 0px 5px;
                    text-align: center;
                    font-size: 1rem;
                }
                button {
                    background-color: transparent;
                    display: flex;
                    opacity: 1;
                }
            }
        }
    }
    
	.total-content{
		background-color: $gray;
		border-style: dotted;
		border-color: $ligth;
		border-radius: 5px;
		border-width: 1px;
		border-spacing: 1px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        max-width: 1200px;

        hr{
            border-style: inset;
            border-width: 0.8px;
            margin: 4px 0px;
        }

        .title{
            font-weight: bold;
            text-align: center;
        }

		.item {
            display: flex;
            .title-preliquidacion{
                width: 100%;
            }
			.opc {
                font-weight: bold;
                width: 50%;
            }
            .val {
                width: 50%;
                text-align: right;
                color: rgb(94, 87, 87);
                font-weight: bold;
            }
		}
    }
    button{
        cursor: pointer;
        margin-top: 10px;
    }

    input {
        border-radius: 5px;
        padding-left: 5px;
        width: 100%;
        height: 25px;
        margin: 10px auto;   
        border: 1px solid $primary;
    }
}

.load-mas {
    text-align: center;
    margin-top: 10px;
    button {
        cursor: pointer;
        background-color: $gray;
        font-size: 0.9rem;
        font-weight: bold;
        padding: 10px;
        border-radius: 5px;
        color: $primary;
    }
}


@media only screen and (min-width: $device-sm) {

    .encabezado-list {
        padding-top: 70px !important;

        .item{
            display: flex !important;

            button{
                margin: 5px 10px !important;
            }

            .img-cliente{
                img{
                    width: 80px !important;
                    margin: 0px 20px !important;
                }
            }

            .start{
                width: 85% !important;
                display: flex !important;
            }

            .end{
                width: 15% !important;
                justify-content: center;
                display: flex !important;
            }
        }
    }

    .lista-agenda{
        padding-top: 10px !important;
    }

    .padding-cliente-list{
        padding: 70px 10px 15px 10px !important;
    }

    .product-card {
        grid-template-columns: repeat(4, 1fr) !important;
    }

    .header-list{
        height: 45px !important;
        p {
            width: 33% !important;
            display: inline-block !important;
            text-align: center !important;
        }
    }

    .contactenos{
        display: flex;
    }
    
}

@media only screen and (min-width: $device-md) {

    .encabezado-list {
        padding-top: 70px !important;

        .item{
            display: flex !important;

            button{
                margin: 5px 10px !important;
            }

            .img-cliente{
                img{
                    width: 80px !important;
                    margin: 0px 20px !important;
                }
            }

            .start{
                width: 85% !important;
                display: flex !important;
            }

            .end{
                width: 15% !important;
                justify-content: center;
                display: flex !important;
            }
        }
    }

    .lista-agenda{
        padding-top: 10px !important;
    }

    .padding-cliente-list{
        padding: 70px 10px 15px 10px !important;
    }

    .ayuda-list{
        
        width: 100%;
        margin: 10px 0%;

        .seccion-ayuda{
            width: 90%;
            margin: 0 5%;
            .font-ayuda{
                font-size: 15px;
                font-weight: bold;
                color: white;
            }
        }
    }

    .product-card {
        grid-template-columns: repeat(5, 1fr) !important;
    }
    #root{
        display: flex;
    }
    .carrito{
        padding: 100px 10px;
        display: inline-block;
        width: 70%;
    }
    .total{
        display: inline-block;
        width: 30%;
        position: initial;
        padding: 100px 10px;
    }
}

@media only screen and (min-width: $device-lg) {

    .encabezado-list {
        padding-top: 70px !important;

        .item{
            display: flex !important;

            button{
                margin: 5px 10px !important;
            }

            .img-cliente{
                img{
                    width: 80px !important;
                    margin: 0px 20px !important;
                }
            }

            .start{
                width: 85% !important;
                display: flex !important;
            }

            .end{
                width: 15% !important;
                justify-content: center;
                display: flex !important;
            }
        }
    }

    .lista-agenda{
        padding-top: 10px !important;
    }

    .padding-cliente-list{
        padding: 70px 10px 15px 10px !important;
    }

    .product-card {
        grid-template-columns: repeat(6, 1fr) !important;
    }
}

@media only screen and (min-width: $device-xl) {

    .encabezado-list {
        padding-top: 70px !important;

        .item{
            display: flex !important;

            button{
                margin: 5px 10px !important;
            }

            .img-cliente{
                img{
                    width: 80px !important;
                    margin: 0px 30px !important;
                }
            }

            .start{
                width: 85% !important;
                display: flex !important;
            }

            .end{
                width: 15% !important;
                justify-content: center;
                display: flex !important;
            }
        }
    }

    .lista-agenda{
        padding-top: 10px !important;
    }

    .padding-cliente-list{
        padding: 70px 10px 15px 10px !important;
    }

    .ayuda-list{
        
        width: 100%;
        margin: 10px 0%;

        .seccion-ayuda{
            width: 90%;
            margin: 0 5%;
            .font-ayuda{
                font-size: 23px;
                font-weight: bold;
                color: white;
            }
        }
    }
}