form {
    .container-form-login {

        border: 1px solid $primary;
        border-radius: 10px;
        padding: 20px 10px;
        margin: 0px 0px 10px 0px;

        .select-type{
            border: 1px solid black;
            text-align: center;
            width: 100%;
            display: flex;

            .user-type-option{
                width: 50%;
                cursor: pointer;
                text-align: center;
            }

            .active{
                background-color: rgba(169, 169, 169, 0.44);
            }
        }

        .form-control {
            display: grid;
            label {
                color: black;
                margin-bottom: 0px;
                margin-top: 10px;
                font-weight: bold;
            }
            input {
                height: 40px;
                background-color: white;
                border: 1px solid white;
                font-size: 0.9rem;
                padding-left: 10px;
                padding-right: 10px;
                border-radius: 5px;
            }
            .error {
                color: $primary;
                font-size: 0.9rem;
                margin-top: 10px;
            }
            /*margin-top: 10px;
            label {
                font-size: 0.7rem;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .input-icon {
                margin-top: 5px;
                border-radius: 1px;
                border: 1px solid $ligth;
            }*/
        }  

        button {
            margin-top: 20px;
        }
    }
}

.checkbox {
    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    .switch {
        margin-right: 10px;
        -webkit-tap-highlight-color: transparent;
    }
    label {
        font-size: 1rem;
        font-weight: bold;
        -webkit-tap-highlight-color: transparent;
    }
    .input {
        -webkit-tap-highlight-color: transparent;
    }
    .input:focus {
        outline: none;
    }
}

.btn-large {
    background-color: $dark;
    width: 100%;
    color: white;
    height: 40px;
    font-size: 0.9rem;
    border-radius: 5px;
}

.outline{
    background-color: white;
    border: 1px solid $primary;
    color: $primary;
}