.CardPassword{

    padding: 10px;
    display: block;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: 80px auto 20px auto;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);

    .seccion {
        width:100%;
        margin-bottom: 20px;
    }
}

@media only screen and (min-width: $device-sm) {
    .CardPassword{
        width: 90% !important;
        display: block !important;
    }
}

@media only screen and (min-width: $device-md) {
    .CardPassword{
        width: 70% !important;
        display: flex !important;
    }
}

@media only screen and (min-width: $device-lg) {
    .CardPassword{
        width: 60% !important;
        display: flex !important;
    }
}

@media only screen and (min-width: $device-xl) {
    .CardPassword{
        width: 50% !important;
        display: flex !important;
    }
}