$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;
$white: #eee;

$width: 100px;

.progress {
  width: 100%;
  text-align: center;
    // height: 100%;
    // position: absolute;
    // background-color: rgba(0,0,0,0.4);
}

// .loader{
 
// }

.circular{
  animation: rotate 2s linear infinite;
  height: $width;
  position: relative;
  width: $width;
}

.path {
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
  stroke:#FFFFFF;
  animation:
   dash 1.5s ease-in-out infinite,
   color 6s ease-in-out infinite
  ;
  stroke-linecap: round;
}

@keyframes rotate{
 100%{
  transform: rotate(360deg);
 }
}
@keyframes dash{
 0%{
  stroke-dasharray: 1,200;
  stroke-dashoffset: 0;
 }
 50%{
  stroke-dasharray: 89,200;
  stroke-dashoffset: -35;
 }
 100%{
  stroke-dasharray: 89,200;
  stroke-dashoffset: -124;
 }
}
// @keyframes color{
//   100%, 0%{
//     stroke: $red;
//   }
//   40%{
//     stroke: $blue;
//   }
//   66%{
//     stroke: $green;
//   }
//   80%, 90%{
//     stroke: $yellow;
//   }
// }