.ui-alert-content {
  position: fixed;
  width: 360px;
  z-index: 2060;
}
.ui-alert-content-top-right {
  top: 20px;
  right: 20px;
}
.ui-alert-content-top-center {
  top: 20px;
  margin: 0 0 0 -180px;
  left: 50%;
}
.ui-alert-content-top-left {
  top: 20px;
  left: 20px;
}
.ui-alert-content-bottom-right {
  bottom: 0;
  right: 20px;
}
.ui-alert-content-bottom-center {
  bottom: 0;
  margin: 0 0 0 -180px;
  left: 50%;
}
.ui-alert-content-bottom-left {
  bottom: 0;
  left: 20px;
}
.ui-alert {
  opacity: 0;
  filter: alpha(opacity=0);
}
.ui-alert p {
  display: table;
}
