.login {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    .login-content {
        .splash {
            // background-color: $primary;
            // background-image: url("./");
            background-size: cover;
            background-color: white;
            width: 100%;
            height: 100vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            // animation-name: showform;
            // animation-duration: 1s;
            .logo-img {
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                animation-name: bounceInLeft;
                animation-duration: 1s;
                img {
                    width: 70%;
                    height: auto;
                    z-index: 1;
                }
            }
            .logo-footer {
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                animation-name: bounceInLeft;
                animation-duration: 1s;
                img {
                    width: 200px;
                    height: auto;
                    z-index: 1;
                }
            }

            .maqueta-img {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                animation-name: bounceInDown;
                animation-duration: 1s;
                img {
                    width: 80%;
                    // height: 600px;
                    max-width: 100%;
                    border-radius: 5px;
                }
            }
            .footer {
                height: 30%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-left: 10%;
                margin-right: 10%;
                animation-name: bounceInRight;
                animation-duration: 1s;
                .footer-text{
                    color: white;
                    text-align: center;
                    font-size: 0.8rem;
                    font-weight: bold;
                }
                .footer-img{
                    margin-top: 20px;
                    text-align: center;
                    img {
                        width: 150px;
                        height: auto;
                    }
                }
            } 
        }
        .login-form {
            display: none;
            width: 100%;
            height: 100vh;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .logo-img {
                // height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                animation-name: bounceInDown;
                animation-duration: 1s;
                img {
                    width: 70%;
                    height: auto;
                    z-index: 1;
                }
            }
            .logo-footer {
                height: 20%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                animation-name: bounceInDown;
                animation-duration: 1s;
                img {
                    width: 200px;
                    height: auto;
                    z-index: 1;
                    margin: 0 20px;
                }
            }
            form {
                width: 60%;
                animation-name: bounceInRight;
                animation-duration: 1s;
                input {
                    border: 1px solid $primary;
                }
                button:hover {
                    background-color: $primary;
                }
            }
        }
    }

    .show {
        .splash {
            display: none;
        }
        .login-form {
            display: flex !important;
        }
    }
}

@media only screen and (min-width: $device-sm) {
    .login {
        .login-content {
            .splash {
                .footer {
                    flex-direction: initial;
                    .footer-text{
                        text-align: left;
                        margin-right: 10px;
                    }
                    .footer-img{
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: $device-md) {
    .login {
        .login-content {
            display: flex;
            .splash{
                display: flex;
                width: 50%;
            }
            .login-form {
                background-color: white;
                display: flex;
                width: 50%;
                form {
                    width: 50%;
                }
            }
            
        }
    }
}