.drop-btn{
    cursor: default !important;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top: 8px;
}

.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: #3e8e41;}

.navbar {
    position: fixed;
    z-index: 1;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.15);
    .nav-header {
        background-color: white;
        .nav-content { 
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            padding: 10px 0px;
            // max-width: 1200px;
            text-align: center;
            .subnav {
                display: flex;
                align-items: center;
                .back {
                    margin: 10px;
                }
            }
            .nav-logo {
                width: 75%;
                img {
                    width: auto;
                    height: 40px;
                }
            }
            .navmenu {
                display: none;
            }
        }
    }

    .button-Search{
        
        padding: 7px 0px 0px 15px;
        button{
            cursor: pointer;
            background-color: white;
            margin: 0px 10px 0px 0px;
        }
    }

    .input-search{
        background-color: white;
        margin-left: auto;
        // margin-right: auto;
        padding: 10px 10px;
        max-width: 1200px;
        text-align: center;
        display: flex;
        input {
            width: 100%;
            background-color: $gray;
            height: 40px;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 1rem;
            border: 1px solid $primary;
        }
    }
}

.hidden {
    display: none;
}

.tabmenu {
    position: fixed;
    bottom: 0px;
    background-color: $primary;
    height: 60px;
    width: 100%;
    box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.15);
    .tab-content {
        height: 100%;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-auto-rows: auto;
        grid-gap: 0.1rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        .tab {
            display: grid;
            align-items: center;
            justify-items: center;
            font-size: 0.8rem;
            opacity: 0.6;
            span {
                color: white;
            }
        }

        .active {
            opacity: 1;
            font-weight: bold;
        }
    }
}

.content {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 10px;
    padding-top: 60px;
}

@media only screen and (min-width: $device-sm) {
    .navbar {
        // display: flex;
        display: flex;
        align-items: center;
        justify-content: center;
        .navbar-header {
            display: flex;
            width: 100%;
            align-items: center;

            .img-navbar{
                width: 30px;
                cursor: pointer;
            }

        }
        .nav-header {
            display: inline-block !important;
            width: 30%;
        }
        .input-search{
            display: flex;
            width: 70%;
        }
    }

    .principal {
        justify-content: left;
    }

    .pedido-list {
        padding: 0px 10px !important;
    }

    .categoria {
        // margin-top: 50px;
        top: 55px;
    }
}

@media only screen and (min-width: $device-md) {
    .principal {
        justify-content: left;
    }
    .navbar {
        .nav-header {
            width: 100%;
            .nav-content {
                display: flex;
                align-items: center;
                justify-content: center;
                .navmenu {
                    display: block !important;
                    width: 100%;
                    ul {
                        display: flex;
                        align-items: center;
                        li {
                            a {
                                padding: 10px;
                                font-size: 0.8rem;
                                font-weight: bold;
                                cursor: pointer;
                            }
                            .active {
                                // border-bottom: 2px solid $primary;
                                color: $primary;
                            }
                            .cart {
                                padding: 0px !important;
                                background-color: $primary;
                                color: white;
                                border-radius: 5px;
                                display: flex;
                                align-items: end;
                                img {
                                    margin: 10px;
                                    margin-right: 10px;
                                }
                                p{
                                    margin: 10px;
                                }
                                .cart-counter {
                                    margin-top: 2px;
                                    position: absolute;
                                    padding-left: 4px;
                                    padding-right: 4px;
                                    background: white;
                                    border-radius: 50%;
                                    font-size: 12px;
                                    margin-left: 20px;
                                    color: black;
                                }
                            }
                            .cart_active {
                                padding: 0px !important;
                                background-color: $primary;
                                color: white;
                                border-radius: 5px;
                                display: flex;
                                align-items: end;
                                img {
                                    margin: 10px;
                                    margin-right: 10px;
                                }
                                p{
                                    margin: 10px;
                                }
                                .cart-counter {
                                    margin-top: 2px;
                                    position: absolute;
                                    padding-left: 4px;
                                    padding-right: 4px;
                                    background: white;
                                    border-radius: 50%;
                                    font-size: 12px;
                                    margin-left: 20px;
                                    color: black;
                                }
                            }
                        }
                    }
                }
            }
        }
        .input-search{
            width: 40%;
        }
    }

    .tabmenu {
        display: none;
    }

}